<template>
  <div class="fcn-contact">
    <s-v-g-icons />
    <slot name="header"></slot>
    <div v-if="stage == 0">
      <form novalidate style="position:relative; display: block;">
        <div class="loadingbox" v-if="loading"></div>
        <div class="fcn-contact__row">
          <div class="fcn-contact__column">
            <div class="fcn-contact__field">
              <input class="fcn-contact__textinput" v-model="fields.username" type="text" placeholder="Imię">
            </div>
            <div role="alert" transition="expand" class="fcn-contact__error" v-if="submitted && ($v.fields.username.$invalid === true)"><span>wymagane pole</span></div>
          </div>
          <div class="fcn-contact__column">
            <div class="fcn-contact__field">
              <input class="fcn-contact__textinput" v-model="fields.email" type="email" placeholder="Adres e-mail">
            </div>
            <div role="alert" transition="expand" class="fcn-contact__error" v-if="submitted && ($v.fields.email.$invalid === true)"><span>wymagany jest poprawny adres e-mail</span></div>
          </div>
          <div class="fcn-contact__column" v-if="showPhone">
            <div class="fcn-contact__field">
              <input class="fcn-contact__textinput" v-model="fields.phone" type="text" placeholder="Numer telefonu">
            </div>
            <div role="alert" transition="expand" class="fcn-contact__error" v-if="submitted && ($v.fields.phone.$invalid === true)"><span>wymagany poprawny numer telefonu</span></div>
          </div>
        </div>
        <div :class="['fcn-contact__row']">
          <div class="fcn-contact__column">
            <a tabindex="0" v-show="!loading" :class="['cta cta-button cta-button--red-o cta-button--bold']" v-on:keydown.enter="submit" aria-label="" v-on:click="submit">WYŚLIJ</a>
          </div>
        </div>
        <div :class="['fcn-contact__row']">
          <div class="fcn-contact__column">
            <div :class="['fcn-contact__checkbox', check_class]">
              <input :id="uid + '_fcn-contact__check1'" name="fcn-contact__check1" v-model="check1" value="check1" type="checkbox" v-on:keyup.enter.stop.prevent="check1 = true;"/>
              <label :for="uid + '_fcn-contact__check1'">
                <transition name="expand">
                  <span><span role="alert" class="fcn-contact__error" v-if="submitted && $v.check1.$invalid">wymagana zgoda </span>Wyrażam zgodę na przetwarzanie danych osobowych <span v-if="checks_with_more === 'yes'" v-show="!toggles.check1">...</span><span transition="expand" v-show="toggles.check1 || checks_with_more === 'no'"> zamieszczonych w powyższym formularzu przez firmę Aiton Caldwell SA do celu marketingu własnego. Zgodę można w każdej chwili wycofać, poprawić lub zmienić. Wycofanie zgody nie będzie miało skutków w stosunku do danych przetwarzanych przed jej wycofaniem. </span></span>
                </transition>
              </label>
              <div tabindex="0" class="fcn-contact__checkbox__more __more_class" v-if="checks_with_more==='yes'" v-on:keydown.enter="toggle('check1')" v-on:click.stop.prevent="toggle('check1')">{{ toggledmark(toggles.check1) }}</div>
            </div>
          </div>
        </div>
      </form>
      <div class="fcn-contact__row" v-if="loading || $v.$error">
        <div class="fcn-contact__column fcn-contact__submit fcn-contact__column--submit ">
          <div v-show="loading">
            <svg width="40px" height="40px">
              <use xlink:href="#hourglass" />
            </svg>
            <small role="alert">proszę czekać... trwa przygotowanie ebooka</small>
          </div>
          <transition name="expand">
            <div class="fcn-contact__errors" v-if="$v.$error" transition="expand">
              <div role="alert" class="fcn-contact__errors__wrapper">
                <strong>Prosimy poprawić błędy w formularzu</strong>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div style="overflow:hidden;" v-else>
      <slot name="success" v-if="!lite">
        <slot name="success_top">
          <h2 class="fcn-contact--success">Pobieranie pliku rozpoczęte</h2>
        </slot>
      </slot>
    </div>
    <slot name="footer" class="footer"></slot>
  </div>
</template>
<script>
import axios from 'axios';
// import vueSelect from 'vue-select'
import VueCookies from 'vue-cookies';

import { validationMixin } from 'vuelidate';
import {
  sameAs, required, email, minLength,
} from 'vuelidate/lib/validators';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import SVGIcons from './SVGIcons';

const checked = sameAs(() => true);


/* eslint-disable */
var dataLayer = window.dataLayer || [];
/* eslint-enable */

export default {
  name: 'Wizard',
  components: {
    SVGIcons,
  },
  mixins: [validationMixin],
  props: {
    host: {
      type: String,
      default: 'fcn.pl',
    },
    filename: {
      type: String,
      defaul: 'ebook_droga_do_infolinii.pdf',
    },
    assetUrl: {
      type: String,
      default: 'fcn-assets/1.0/signup',
    },
    showPhone: {
      type: Boolean,
      default: false,
    },
    thankyoupage_url: {
      type: String,
      default: '',
    },
    tags: {
      type: String,
      default: '',
    },
    lite: {
      type: Boolean,
      default: false,
    },
    test: {
      type: Boolean,
      default: false,
    },
    gacategory: {
      type: String,
      default: 'Wizard2_Standard',
    },
    theme: {
      type: String,
      default: 'fcn-contrast',
    },
    checks: {
      type: String,
      default: 'collapsed',
    },
    checks_with_more: {
      type: String,
      default: 'yes',
    },
    show_more: {
      type: String,
      default: 'yes',
    },
    check_class: {
      type: String,
      default: '',
    },
    submit_width: {
      type: String,
      default: '',
    },
    submit_align: {
      type: String,
      default: '',
    },
    partner_id: {
      type: String,
      default: '',
    },
  },
  validations() {
    const basic = {
      fields: {
        email: {
          required,
          email,
        },
        username: {
          required,
          minLength: minLength(2),
        },
        phone: {
          phone: (data) => {
            if (data) {
              const n = parsePhoneNumberFromString(data, 'PL');
              if (n) {
                console.log(n.number, n.number.length);
                if (n.number.length < 12) {
                  return false;
                }
                return n.isPossible();
              }
              return false;
            }
            return true;
          }
        },
      },

      check1: {
        checked,
      },
      /*
      check2: {
        checked
      },
      check3: {
        checked
      },
      check4: {
        checked
      }
      */
    };
    return basic;
  },
  watch: {
    'fields.username': function (newValue, oldValue) {
      if (oldValue === this.suggested_username) {
        this.setSuggestedUsername('', 'username__changed');
      }
    },
  },
  data() {
    return {
      uid: 'fcn345',
      msg: '',
      stage: 0,
      phoneNumber: '',
      show_checks: false,
      arrow_icon: '#icon-chevron-down',
      opts: {
        sitekey: '6LdOThgTAAAAACKrNXa2f20yKzMCKokKjUCaA6Cz',
      },
      fields: {
        email: '',
        username: '',
        phone: '',
        strefa: '',
        captcha: false,
        checks: [],
        partner_id: '',
        tags: '',
      },
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      toggles: {
        check1: false,
        check2: false,
        check3: false,
        check4: false,
      },
      jwt: false,

      submitted: false,
      loading: false,
      checking: false,
    };
  },
  created() {
    if (this.checks === 'expanded') {
      this.toggles.check1 = true;
      this.toggles.check2 = true;
      this.toggles.check3 = true;
      this.toggles.check4 = true;
    }
  },
  /*
  validators: { // `numeric` and `url` custom validator is local registration
    email: {
      check: function (val) {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)
      }
    },
    exists: {
      check: function (val) {
        // console.log('validating ' + val + 'username')
        // return this.vm.suggestLogin()
      },
      message: 'xxx'
    }
  },
  */
  methods: {
    ga(category, action, label) {
      /* eslint-disable */
      // var dataLayer = dataLayer || []
      dataLayer.push({ event: 'gtmEvent', eventCategory: category, eventAction: action, eventLabel: label })
      /* eslint-enable */
    },
    thankyoupage() {
      this.$emit('success', true);
      if (this.thankyoupage_url) {
        /* eslint-disable */
        try {
          // var dataLayer = dataLayer || []
          /*
          dataLayer.push({
            event: 'gtmVirtualPage',
            virtualPageURL: this.thankyoupage_url,
            virtualPageTitle: ''
          })
          dataLayer.push({
            event: 'gtmConversion',
            conversionDetails: { partner_id: this.fields.partner_id, strefa: this.fields.strefa, tags: this.fields.tags.replace(' ','').split(',') }
          })
          */
        } catch (err) {
          // console.log(err)
        }
        /* eslint-enable */
      }
    },
    onVerify(response) {
      this.fields.captcha = response;
      // this.ga(this.gacategory, 'Kliknięcie w Captcha', 'Nie jestem robotem')
    },
    onExpired() {
      this.fields.captcha = false;
      if (this.$refs.recaptcha) {
        this.$refs.recaptcha.reset();
      }
      // this.ga(this.gacategory, 'Wygaśnięcie captcha', 'Wygaśnięcie captcha')
    },
    reset() {
      this.$resetValidation(() => {
      });
    },
    track(el) {
      // dataLayer.push({event:'gtmEvent', eventCategory:'form', eventAction:'kliknięcie', eventLabel: el})
    },
    onInvalid(stage, x) {
      if (this.stage === stage && this.submitted) {
        // dataLayer.push({event:'gtmEvent', eventCategory:'form', eventAction:'validation failed on field', eventLabel: x})
      }
    },
    toggle(elem) {
      this.toggles[elem] = !this.toggles[elem];
    },
    toggled(elem) {
      if (elem) {
        return '[zwiń]';
      }
      return '[rozwiń]';
    },
    toggledmark(elem) {
      if (elem) {
        return '-';
      }
      return '+';
    },

    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.filename); // or any other extension
      document.body.appendChild(link);
      link.click();
    },

    downloadWithAxios(fileUrl) {
      axios({
        method: 'get',
        url: fileUrl,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log('error occured'));
    },
    submit() {
      // this.$validate(true)
      this.$v.$touch();
      // this.fields.partner_id = this.partner_id
      this.fields.tags = this.tags;
      if (this.test) {
        // this.loading = true
        // this.ga(this.gacategory, 'Kliknięcie w Wyślij - powodzenie', 'Zamów ebook')
        this.thankyoupage();
        this.stage = 1;
        return;
      }
      this.submitted = true;

      if (!this.$v.$invalid) {
        this.submitted = false;
        this.loading = true;

        let postFields = { ...this.fields };
        if (postFields.phone) {
          const n = parsePhoneNumberFromString(postFields.phone, 'PL');
          if (n) {
            postFields.phone = n.number;
          }
        }
        axios.post(`https://${this.host}/wp-json/${this.assetUrl}`, postFields).then((response) => {
          if (response.data.ebook) {
            this.downloadWithAxios(response.data.ebook);
          }
          // if (this.lite) {
          //  this.ga(this.gacategory, 'Rejestracja', 'Strefa: ' + this.fields.strefa.value + ' / ' + this.fields.strefa.l)
          // } else {
          //  this.ga(this.gacategory, 'Kliknięcie w Załóż konto - powodzenie', 'Załóż konto')
          // }
          this.thankyoupage();
          this.loading = false;
          // if (this.$refs.recaptcha) {
          //  this.$refs.recaptcha.reset()
          // }
          this.stage = 1;


          const { smclient } = response.data;
          // todo: smclient cookie


          if (smclient.success) {
            //            $.cookie('smclient', smclient.contactId, {expires: 365 * 10, path: '/'})
            $cookies.set('smclient', smclient.contactId, 365 * 10, '/');
          }

          // todo: ga event
          this.ga('FCN_MQL', 'pobranie_ebooka', 'infolinia');
        }).catch((response) => {
          // if (this.$refs.recaptcha) {
          //  this.$refs.recaptcha.reset()
          // }
          this.loading = false;
          if (response.data.status === 'error' && response.data.error_code === 'captcha') {
            // this.ga(this.gacategory, 'Kliknięcie w Załóż konto - niepowodzenie (captcha)', 'Załóż konto')
            // self.$setValidationErrors([
            //  { field: 'captcha', message: 'reCaptcha - nie została poprawnie wypełniona' }
            // ])
          } else if (response.data.status === 'error' && response.data.error_code === 'account') {
            // this.ga(this.gacategory, 'Kliknięcie w Załóż konto - niepowodzenie (login zajęty)', 'Załóż konto')
            // self.$setValidationErrors([
            //  { field: 'username', message: 'Wybrany login jest już zajęty.' }
            // ])
          } else if (response.data.status === 'error' && response.data.error_code === 'email') {
            // this.ga(this.gacategory, 'Kliknięcie w Załóż konto - niepowodzenie (email blocked)', 'Załóż konto')
            // self.$setValidationErrors([
            //  { field: 'email', message: 'Adres email nieprawidłowy.' }
            // ])
          } else {
            // this.ga(this.gacategory, 'Kliknięcie w Załóż konto - niepowodzenie', 'Załóż konto')
          }
        });
      } else {
        if (!this.$v.check1.required) {
          this.show_checks = true;
        }
        // this.ga(this.gacategory, 'Kliknięcie w Załóż konto - błąd walidacji formularza', 'Załóż konto')
      }
    },
  },
  mounted() {
    this.$emit('mounted', true);
  },
};
</script>
<style lang="scss" scope>
  @import "~breakpoint-sass/stylesheets/breakpoint";
  @import "../sass/custom_colors";
  @import "../sass/themes";
  @import "../sass/theme-helpers";
  @import "../sass/custom_text_styles";
  %focused {
    outline: 3px solid #1b541d;
    box-shadow: 0px 0px 4px White;
  }
  .fcn-contact {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    box-sizing: border-box;
    transition: all 0.3s ease;
    font-size: 16px;
    overflow: hidden;
    padding-top: 1rem;
    .loadingbox {
      position: absolute;
      z-index: 10;
      touch-action: none;
      left: 0;
      top: 0;
      right: 0;
      bottom :0;
      background: rgba(255,255,255,0.5);
    }
    &--success {
      color: White;
    }
    form {
      position:relative;
    }
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      // margin-top: 0 !important;
      align-items: flex-start;
      // padding: 0 2px;
      &--notopmargin {
        margin-top: 0 !important;
      }
    }
    &__column {
      flex-grow: 1;
      padding: 1em;
      padding-top: 0;
      flex-basis: 200px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      &--submit {
        flex-basis: 230px;
        flex-grow: 4;
      }
    }
    &__textinput {
      padding: 1em;
      font-size: 1em;
      box-sizing: border-box;
      text-align: center;
      display:block;
      width:100%;
      min-width:250px;
      border: 1px solid #ccc;
    }
    &__checkbox {
      font-size: 0.9em;
      position:relative;
      padding-left:2.5em;
      display:block;
      text-align: left;

      input {
        position:absolute;
        top: 0.2em;
        left: 0;
        width: 2em;
      }
      a {
        color:#369829;
      }
      label {
        cursor: pointer;
      }
    }
    &__submit {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align:center;
      flex-direction: column;
      //margin-top: em !important;
      small {
        color: #888;
        text-align:center;
        display:block;
      }
    }
    &__errors {
      font-size: 1em;
      margin-top: 1em;
      border: 1px solid #9e0000;
      background: #9e0000;
      &__wrapper {
        padding: 0.5em 1em!important;
        color: White;
      }
    }
    &__stage {
    }
    &__toggle {
      color: #369829;
      transition: all 0.2s ease;
      &:hover {
        color: lighten(#369829,25%);
        transition: all 0.2s ease;
      }
    }
    &__ok {
      color: #369829;
      display: flex;
      font-size:1em;
      text-align:left;
      // padding-left: 2em;
      position: relative;
      align-items: flex-start;
      margin-top: 0.25em;
      svg {
        fill: #369829;
        // position: absolute;
        left: 0;
        min-width: 2em;
        height: 1.2em;

      }
    }
    &__error {
      display: flex;
      // color: Red !important;
      font-size:1em;
      text-align: left;
      // padding-left: 2em;
      position: relative;
      align-items: flex-start;
      margin-top: 0.25em;
      background: #9e0000;
      padding: 0.25rem 0.5rem;
      color: White !important;
      svg {
        fill: Red;
        // position: absolute;
        left: 0;
        min-width: 2em;
        height: 1.2em;
      }
      a {
        text-decoration: none;
        display: inline;
        border-bottom: 1px dotted #008000;
        color: #008000;
        cursor: pointer;
        &:hover {
          color: #228822;
        }
      }
    }
    &__suggest {
      display: flex;
      align-items: flex-start;
      color: #888;
      font-size:1em;
      background: #ffffd1;
      padding: 0.5em 0em;
      padding-right: 0.5em;
      border-radius: 3px;
      text-align: left;
      margin-top: 0.25em;
      svg {
        fill: #888;
        min-width: 2em;
        height: 1.2em;
      }
      a {
        text-decoration: none;
        display: inline;
        border-bottom: 1px dotted #008000;
        color: #008000;
        cursor: pointer;
        &:hover {
          color: #228822;
        }
      }
    }
    &--width-33 {
      width:33%;
      // @include breakpoint(max-width 480px) {
      //  width:100%;
      // }
    }
    &--width-50 {
      width:50%;
      // @include breakpoint(max-width 480px) {
      //   width:100%;
      // }
      // @include breakpoint(min-width 481px, max-width 622px) {
      //  width:66%;
      // }
    }
    &--width-66 {
      width:66%;
      // @include breakpoint(max-width 620px) {
      //   width:100%;
      // }
    }
    &--width-75 {
      width:75%;
      // @include breakpoint(max-width 480px) {
      //  width:100%;
      // }
    }
    &--centered {
      margin: 0 auto;
    }
    .fcn-contact__phoneNumber {
      display: block;
      text-align: center;
      font-size: 2rem;
    }
    .fcn-contact__with-label {
      display:flex;
      align-items: baseline;
      flex-wrap: wrap;
      width:100%;
      label {
        display:none;
        min-width: 7em;
        padding-right: 1em;
        text-align: left;
        // @include breakpoint(min-width 480px) {
        //  text-align: right;
        // }
      }
      > div {
        width: auto !important;
        flex-grow:1;
        //flex-basis:200px;
        min-width:160px;
      }
    }
  }
  .fcn-contact {
    .input-align--centered {
      .fcn-contact__textinput {
        margin: 0 auto;
      }
    }

    .input-align--left {
      .fcn-contact__textinput {
        margin-right: auto;
      }
    }

    .input-align--right {
      .fcn-contact__textinput {
        margin-left: auto;
      }
    }

    .fcn-contact-input--50 {
      .fcn-contact__textinput {
        width: 50%;
      }
    }

    .fcn-contact-input--66 {
      .fcn-contact__textinput {
        width: 66%;
      }
    }

    .fcn-contact-input--75 {
      .fcn-contact__textinput {
        width: 75%;
      }
    }

    .fcn-contact--centered {
      .fcn-contact__submit {
        display: flex;
        align-items: center;
      }
    }

    .recaptcha-align--center {
      .recaptcha-container > div > div {
        margin: 0 auto;
      }

      .recaptcha-container .fcn-contact__error {
        text-align: center;
      }
    }

    .recaptcha-align--left {
      .recaptcha-container > div > div {
        margin-right: auto;
      }

      .recaptcha-container .fcn-contact__error {
        text-align: left;
      }
    }

    .recaptcha-align--right {
      .recaptcha-container > div > div {
        margin-left: auto;
      }

      .recaptcha-container .fcn-contact__error {
        text-align: right;
      }
    }

    .submit-align--left .fcn-contact__submit {
      align-items: flex-start !important;
    }

    .submit-align--right .fcn-contact__submit {
      align-items: flex-end !important;
    }

    .submit-align--centered .fcn-contact__submit {
      align-items: center;
    }

    .submit-align--stretch .fcn-contact__submit {
      align-items: stretch;
    }

    .submit--width-33 .cta-button {
      min-width: 33%;
      box-sizing: border-box;
    }

    .submit--width-50 .cta-button {
      min-width: 50%;
      box-sizing: border-box;
    }

    .submit--width-75 .cta-button {
      min-width: 75%;
      box-sizing: border-box;
    }

    .submit--width-100 .cta-button {
      min-width: 100%;
      box-sizing: border-box;
    }

    /* .expand-enter defines the starting state for entering */
    /* .expand-leave defines the ending state for leaving */
    .expand-enter {
      height: 0 !important;
      opacity: 0 !important;
    }

    .expand-enter-active {
      transition: all 1s ease !important;
    }

    .expand-enter-to {
      height: auto !important;
      overflow: hidden;
      opacity: 1 !important;
    }

    .expand-leave-active {
      transition: all 1s ease !important;
    }

    .expand-leave-to {
      height: 0 !important;
      opacity: 0 !important;
      overflow: hidden;
    }

    .fcn-contact__stage--1 {
      padding-top: 3em !important;
    }

    .fcn-contact__stage--1.expand-transition {
      transition-delay: 0.3s !important;
    }

    .fcn-contact__stage--1 {
      padding-top: 3em !important;
    }

    .fcn-contact__stage--1.expand-transition {
      transition-delay: 0.3s !important;
    }

    %wizard_box {
      position: relative;
      border: 1px solid #e8e8e8;
      padding: 1em;
      background: #f5f5f5;

      color: #aaa;
      font-weight: 400;
    }

    .fcn-contact {
      // checkboxy stylowanie

      &__stackedrows {
        width: 100%;
      }

      &__checks__button {
        @extend %wizard_box;
        text-align: center;
        cursor: pointer;

        &--unfolded {
          transition: all 0.4s ease;

          > svg {
            transform-origin: 50%;
            transform: rotateX(180deg) translateX(0) !important;
            transition: all 0.2s ease;
          }
        }

        > svg {
          transform-origin: 50%;
          transform: rotateX(0deg) translateX(0);
          transition: all 0.4s ease;
          float: right;
        }

        /*
        &:after {
          font-family: ac-icons;
          content: 'n';
          margin-left: 1em;
          transition: .3s all ease;
          transform-origin: 50%;
          transform: rotateZ(360deg) translateX(0);
          position:absolute;
        }
        */
      }

      &__checks__show_more {
        color: #4c3b40;
      }

      &__checkbox {
        @extend %wizard_box;
        background: transparent;
        color: White;
        border-color: transparent;
        display: flex;
        padding: 0;

        .fcn-contact__error {
          display: inline-block;
          padding-right: 0.5em;
          margin-top: 0;
        }

        &__more {
          width: auto;
          // border: 1px solid #e8e8e8;
          display: flex;
          //align-self: center;
          padding: 0 0.5em;
          //align-self: stretch;
          margin-left: 0.5em;
          /* justify-content: initial; */
          // background: #eaeaea;
          font-size: 2em;
          //align-items: center;
          min-width: 0.5em;
          /* text-align: center; */
          justify-content: center;
          cursor: pointer;
          align-items: center;
        }

        input {
          opacity: 0;
          float: left;
        }

        label {
          display: flex;
          align-items: center;
          padding: 1em;
          flex-grow: 1;

          &:before {
            content: ' ';
            flex-shrink: 0;
            margin-right: 1em;
            margin-left: 0em;
            width: 1em;
            height: 1em;
            display: block;
            background: white;
            border: 1px solid #A9A9A9;
            align-self: center;
          }

          span {
            align-self: flex-start;
          }
        }

        input[type=checkbox] + label::after {
          content: ' ';
          position: absolute;
          left: 1.05em;
          /* top: -3px; */
          width: 1em;
          height: 1em;
          display: block;
          z-index: 1;
          background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjE4MS4yIDI3MyAxNyAxNiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAxODEuMiAyNzMgMTcgMTYiPjxwYXRoIGQ9Ik0tMzA2LjMgNTEuMmwtMTEzLTExM2MtOC42LTguNi0yNC04LjYtMzQuMyAwbC01MDYuOSA1MDYuOS0yMTIuNC0yMTIuNGMtOC42LTguNi0yNC04LjYtMzQuMyAwbC0xMTMgMTEzYy04LjYgOC42LTguNiAyNCAwIDM0LjNsMjMxLjIgMjMxLjIgMTEzIDExM2M4LjYgOC42IDI0IDguNiAzNC4zIDBsMTEzLTExMyA1MjQtNTI0YzctMTAuMyA3LTI1LjctMS42LTM2eiIvPjxwYXRoIGZpbGw9IiMzNzM3MzciIGQ9Ik0xOTcuNiAyNzcuMmwtMS42LTEuNmMtLjEtLjEtLjMtLjEtLjUgMGwtNy40IDcuNC0zLjEtMy4xYy0uMS0uMS0uMy0uMS0uNSAwbC0xLjYgMS42Yy0uMS4xLS4xLjMgMCAuNWwzLjMgMy4zIDEuNiAxLjZjLjEuMS4zLjEuNSAwbDEuNi0xLjYgNy42LTcuNmMuMy0uMS4zLS4zLjEtLjV6Ii8+PHBhdGggZD0iTTExODcuMSAxNDMuN2wtNTYuNS01Ni41Yy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTI1My41IDI1My41LTEwNi4yLTEwNi4yYy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTU2LjUgNTYuNWMtNS4xIDUuMS01LjEgMTIgMCAxNy4xbDExNC43IDExNC43IDU2LjUgNTYuNWM1LjEgNS4xIDEyIDUuMSAxNy4xIDBsNTYuNS01Ni41IDI2Mi0yNjJjNS4yLTMuNCA1LjItMTIgLjEtMTcuMXpNMTYzNC4xIDE2OS40bC0zNy43LTM3LjdjLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0xNjkuNSAxNjkuNS03MC4yLTcxLjljLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0zNy43IDM3LjdjLTMuNCAzLjQtMy40IDguNiAwIDEybDc3LjEgNzcuMSAzNy43IDM3LjdjMy40IDMuNCA4LjYgMy40IDEyIDBsMzcuNy0zNy43IDE3NC43LTE3Ni40YzEuNi0xLjcgMS42LTYuOS0uMS0xMC4zeiIvPjwvc3ZnPg==) center center no-repeat;
          transition: all .3s ease;
          transform: scale(0.6);
          opacity: 0;
          align-self: center;
        }

        input[type=checkbox]:checked + label::after {
          transform: scale(0.99);
          opacity: 1;
        }

        /* focus */
        [data-whatinput="keyboard"] & input[type=checkbox]:focus + label {
          @extend %focused;
        }
      }
    }

    /* buttons */
    .cta-button {
      @include themify() {
        background: themed(mainColor);
      }
      color: white;
      padding: 1em 2em;
      text-decoration: none;
      border: 0;
      //text-transform: uppercase;
      text-align: center;
      display: inline-block;
      border-radius: 0.25em;
      cursor: pointer;
      text-shadow: none;

      &--small {
        font-size: 0.8em !important;
        padding: 0.5em 1em !important;
      }

      &--big {
        padding: 1em 2.5em !important;
        font-size: 1.3em !important;
      }

      &--upcase {
        text-transform: uppercase !important;
      }

      &--bold {
        font-weight: bold;
      }

      &--inverted {
        @include themify() {
          color: themed(mainColor);
          background: white;
        }
      }

      &--width-100 {
        min-width: 100% !important;
      }

      &--width-75 {
        min-width: 66% !important;
      }

      &--width-66 {
        min-width: 66% !important;
      }

      &--width-50 {
        min-width: 50% !important;
      }

      &--width-33 {
        min-width: 33% !important;
      }

      &--width-25 {
        min-width: 25% !important;
      }
    }

    /* custom colors */
    .cta-button {
      @each $color in $custom_style_colors {
        &--#{nth($color,1)} {
          @include themify() {
            background: themed(#{nth($color,1)}Color, #{nth($color,2)}) !important;
            color: White;
          }
        }
        &--#{nth($color,1)}-o {
          background-color: nth($color, 2) !important;
        }
        &--#{nth($color,1)}-inverted {
          @include themify() {
            color: themed(#{nth($color,1)}Color, #{nth($color,2)}) !important;
            background: White;
          }
        }
      }
    }
  }
</style>
