<!--<template>
  <div id="app">
    <FcnContactForm :show-phone="true"/>
  </div>
</template>-->
<script>
import FcnContactForm from './components/FcnContactForm.vue';

export default FcnContactForm;
/*
export default {
  name: 'app',
  components: {
    FcnContactForm,
  },
};
*/
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
